<!--订单 待派单 派单地图-->
<template>
  <div style="position: relative;height: 100vh;">
    <div id="container"></div>
    <div id="panel" style="display: none;"></div>

    <div class="content">
      <!--<div style="position:absolute;top: 0;left: 28%">-->
      <!--  <div class="content_input1" style="text-align: center;">-->
      <!--    <div>-->
      <!--      <img src="@/assets/images/home/order/active1.png" alt="" style="width: 14px;height: 16px;vertical-align: middle;margin-right: 10px;">-->
      <!--      <span style="font-size: 16px;font-weight: 500;color: #ffffff;vertical-align: middle;">派单超时</span>-->
      <!--    </div>-->
      <!--    <div style="margin-top: 10px;">-->
      <!--      <span style="font-size: 27px;font-weight: 700;color: #FFB756;">{{statistics.order_response_timeout_count}}</span>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--  <div class="content_input1" style="text-align: center;">-->
      <!--    <div>-->
      <!--      <img src="@/assets/images/home/order/active1.png" alt="" style="width: 14px;height: 16px;vertical-align: middle;margin-right: 10px;">-->
      <!--      <span style="font-size: 16px;font-weight: 500;color: #ffffff;vertical-align: middle;">到达超时</span>-->
      <!--    </div>-->
      <!--    <div style="margin-top: 10px;">-->
      <!--      <span style="font-size: 27px;font-weight: 700;color: #22E1FF;">{{statistics.order_dispatch_timeout_count}}</span>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--  <div class="content_input1" style="text-align: center;">-->
      <!--    <div>-->
      <!--      <img src="@/assets/images/home/order/active1.png" alt="" style="width: 14px;height: 16px;vertical-align: middle;margin-right: 10px;">-->
      <!--      <span style="font-size: 16px;font-weight: 500;color: #ffffff;vertical-align: middle;">完成超时</span>-->
      <!--    </div>-->
      <!--    <div style="margin-top: 10px;">-->
      <!--      <span style="font-size: 27px;font-weight: 700;color: #52E182;">{{statistics.order_receiving_timeout_count}}</span>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--  <div class="content_input1" style="text-align: center; margin-right: 20px;">-->
      <!--    <div>-->
      <!--      <img src="@/assets/images/home/order/active1.png" alt="" style="width: 14px;height: 16px;vertical-align: middle;margin-right: 10px;">-->
      <!--      <span style="font-size: 16px;font-weight: 500;color: #ffffff;vertical-align: middle;">预约超时</span>-->
      <!--    </div>-->
      <!--    <div style="margin-top: 10px;">-->
      <!--      <span style="font-size: 27px;font-weight: 700;color: #CC00FF;">{{statistics.order_finish_timeout_count}}</span>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--</div>-->

      <div class="content1">
        <div class="content_input3" ref="element">
          <div class="zuoceliebiaotitle">
            <div style="display: flex;justify-content: center;align-items: center;">
              <div class="spankuai" v-for="(item,index) in list" :key="index"  :class="{spankuai1:index==isShow}" @click="clickactive(item,index)">
                <el-badge :value="item.corner" class="item">
                  <span>{{item.label}}</span>
                </el-badge>
              </div>
              <div class="zuoceliebiaotitle1" @click="returnPage">
                <i class="el-icon-back"></i>
                <span style="margin-left: 5px;">返回</span>
              </div>
            </div>
          </div>

          <div class="inputbg bianjuleft">
            <el-input
                @blur="querykeyword"
                placeholder="请输入内容"
                v-model="keyword">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>

          <div class='bianjuleft'>
            <div>
              <div class="kuai" v-for="(item,index) in dataList" :key="index" :class="{kuai1:index==isShow1}" @click="activeList(item,index)">
                <el-row :gutter="15" style="display: flex;align-items: center;">
                  <el-col :span="8" style="text-align: center;">
                    <div style="font-size: 16px;font-weight: 500;color: #202033;">{{item.order_times}}</div>
                    <div style="font-size: 13px;font-weight: 500;color: #5976e1;">{{item.license_plate}}</div>
                  </el-col>
                  <el-col :span="10" style="text-align: center;">
                    <div v-if="isShow == 0">
                      <img src="@/assets/images/home/order/daidiaodu.png" alt="" class="font1">
                      <span class="font2">待调度</span>
                    </div>
                    <div v-else>
                      <img src="@/assets/images/home/order/yidiaodu.png" alt="" class="font1">
                      <span class="font2">服务中</span>
                    </div>
                  </el-col>
                  <el-col :span="6" style="text-align: center;">
                    <span class="font3">{{item.order_time}}</span>
                  </el-col>
                </el-row>
                <!--定位小角标-->
                <div class="jiaobiao">
                  <span class="font4">实时</span>
                </div>
              </div>
              <div class='bianju1' v-if="dataList.length > 0">
                <el-pagination
                    style="text-align: center; margin-top: 20px"
                    layout="prev, pager, next"
                    :total="dataListtotal"
                    :page-size="dataListwhere.limit"
                    :current-page="dataListwhere.page"
                    @size-change="dataListChangeSize"
                    @current-change="dataListcurrentChange"
                    background>
                </el-pagination>
              </div>
              <div v-if="dataList.length == 0">
                <el-empty description="暂无数据"></el-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tanchuang" v-if="showcurrent == true">
      <div class="font5">
        <span class="font6">{{detalis.order_info.license_plate}}    {{detalis.order_info.items}}</span>
        <span class="font7">非事故</span>
      </div>
      <div class="tanchuang_b">
        <el-row :gutter="15" class='bianju1'>
          <el-col :span="7">
            <span class="font8">客户电话：</span>
          </el-col>
          <el-col :span="17">
            <span class='font8'>{{detalis.order_info.owners}}  {{detalis.order_info.owners_phone}}</span>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="display: flex;align-items: center;margin-bottom: 10px;">
          <el-col :span="7">
            <span class='font8'>免拖公里：</span>
          </el-col>
          <el-col :span="17">
            <!--<el-input-->
            <!--    style="width: 70px!important;"-->
            <!--    placeholder="请输入内容"-->
            <!--    v-model="form.input"-->
            <!--    clearable>-->
            <!--</el-input>-->
            <span class="font9">{{detalis.order_info.free_trailer}}km</span>
            <!--<span style="color: #FEA737;margin-left: 15px;">确认</span>-->
          </el-col>
        </el-row>
        <div v-if="detalis.order_info.odd_even_address == 'double'">
          <el-row :gutter="15" class='bianju1'>
            <el-col :span="7">
              <span class='font8'>案件地：</span>
            </el-col>
            <el-col :span="17">
              <span class='font8'>{{detalis.order_info.starting_point}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="15" class='bianju1'>
            <el-col :span="7">
              <span class='font8'>目的地：</span>
            </el-col>
            <el-col :span="17">
              <span class='font8'>{{detalis.order_info.destination}}</span>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-row :gutter="15" class='bianju1'>
            <el-col :span="7">
              <span class='font8'>目的地：</span>
            </el-col>
            <el-col :span="17">
              <span class='font8'>{{detalis.order_info.starting_point}}</span>
            </el-col>
          </el-row>
        </div>

        <el-row :gutter="15" class='bianju1'>
          <el-col :span="7">
            <span class='font8'>订单来源：</span>
          </el-col>
          <el-col :span="17">
            <span class='font8'>{{detalis.order_info.source}}</span>
          </el-col>
        </el-row>
        <el-row :gutter="15" class='bianju1'>
          <el-col :span="7">
            <span class='font8'>订单来源：</span>
          </el-col>
          <el-col :span="17">
            <span class='font8'>{{detalis.order_info.affiliation}}</span>
          </el-col>
        </el-row>
        <el-row :gutter="15" class='bianju1'>
          <el-col :span="7">
            <span class='font8'>客户备注：</span>
          </el-col>
          <el-col :span="17">
            <span class='font8'>{{detalis.order_info.remark}}</span>
          </el-col>
        </el-row>

        <div class="font10"></div>

        <el-row class="kuai2">
          <span class="font11"></span>
          <span class="font12">服务司机</span>
        </el-row>

        <el-row :gutter="15" style="margin-top: 10px;">
          <div class="bgbg">
            <el-row :gutter="15" class='bianju1'>
              <el-col :span="8">
                <span class="font13"></span>
                <span class="font12">{{detalis.driver_id.realname}}</span>
              </el-col>
              <el-col :span="8">
                <span class="font14">{{detalis.driver_id.phone}}</span>
              </el-col>
              <el-col :span="8">
                <img src="@/assets/images/home/ditu.png" alt="" class="font15">
                <span class="font14">{{detalis.driver_id.distance}}</span>
              </el-col>
            </el-row>
            <!--<el-row class='bianju1'>-->
            <!--  <span class='font8'>15km内150.00，起出7元/公里</span>-->
            <!--</el-row>-->
            <el-row>
              <span class="font16">位置更新：{{detalis.driver_id.coord_update_time}}</span>
            </el-row>
          </div>
          <div class="kuai3">
            <el-button @click="showcurrent = false">关闭</el-button>
          </div>
        </el-row>

      </div>
    </div>

    <!--<div style="margin-top: 20px;text-align:right;">-->
    <!--  <el-button @click="returnPage">返回</el-button>-->
    <!--</div>-->

    <div class="content_input4" v-if="showcurrent == true">
      <div style="text-align: center;">
        <div class="content_input4_div" v-for="(item,index) in Identity" :key="index" :class="{content_input4_div1:index==isIdentity}" @click="activeisIdentity(item,index)">
          {{item.label}}
        </div>
      </div>

      <!--司机-->
      <div v-if="isIdentity == 0">
        <div class='bianjuleft'>
          <div v-for="(item,index) in DriverIdentity" :key="index" class="DriverIdentityList" :class="{DriverIdentityList1:index==isDriverIdentity}" @click="activeDriverIdentity(item,index)">
            {{item.label}}
          </div>
        </div>

        <!-- 搜索 -->
        <el-row :gutter="15" class='kuai4'>
          <el-col :span="16">
            <div class="shurukuang1">
              <el-input v-model="where.keyword" placeholder="请输入关键词" prefix-icon="el-icon-search" clearable></el-input>
            </div>
          </el-col>
          <!--外协司机不展示距离筛选-->
          <el-col :span="4" v-if="isDriverIdentity != 3">
            <div class="xialakuang1">
              <el-select
                  clearable
                  v-model="where.range"
                  placeholder="请选择距离"
                  class="ele-fluid">
                <el-option
                    v-for="(item) in dict.type.distance"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
          </el-col>
          <el-col :span="4">
            <div style="text-align: center;">
              <el-button type="primary" @click="search">搜索</el-button>
            </div>
          </el-col>
        </el-row>

        <!--判断下外协司机不展示状态筛选-->
        <div class='kuai4' v-if="isDriverIdentity != 3">
          <div v-for="(item,index) in StatusList" :key="index" class="StatusList" :class="{StatusList1:index==isstatus}" @click="activeisstatus(item,index)">
            {{item.label}}
          </div>
        </div>

        <div class='kuai4'>
          <div class='kuai6' v-for="(item,index) in sijilistList" :key="index" >
            <div class='kuai7'>
              <img v-if="index==sijilistlistisShow" src="@/assets/images/home/xuanzhonganniu.png" alt="">
              <img v-else src="@/assets/images/home/weixuanzhonganniu.png" alt="">
            </div>
            <div class="listactive" :class="{listactive1:index==sijilistlistisShow}" @click="sijilistListClick(item,index)">
              <div>
                <span v-if="item.biaoshi == 0" class="font25"></span>
                <span v-if="item.biaoshi == 1" class="font26"></span>
                <span v-if="item.biaoshi == 2" class="font27"></span>
                <span class="font24">
                  <span style="vertical-align: middle;">{{item.realname}}</span>
                  <span class="font28">{{item.license_plate_number}}</span>
                  <img src="@/assets/images/home/juli.png" alt="" class="font29">
                  <span class="font30" v-if="isDriverIdentity != 3">{{item.distance}}km</span>
                  <span class="font31" v-if="item.driver_price != '' && isDriverIdentity != 3">预计 <span style="color: red;">{{item.driver_price}}</span> 元</span>
                </span>
              </div>
              <div class='bianjuleft'>
                <span class="font21" v-if="isDriverIdentity != 3">当前坐标所在地：{{item.id_address}}</span>
                <span class="font32">{{item.phone}}</span>
                <img src="@/assets/images/home/dianhua.png" alt="" class="font33">
              </div>
              <div v-if="isDriverIdentity == 2">
                <span class="font21">归属：{{item.company_name}}</span>
              </div>
            </div>
          </div>
          <el-empty description="暂无数据" v-if="sijilistList.length == 0"></el-empty>
          <div class='bianju1' v-if="sijilistList.length > 0">
            <el-pagination
                style="text-align: center; margin-top: 20px"
                layout="prev, pager, next"
                :total="total"
                :page-size="where.limit"
                :current-page="where.page"
                @size-change="ChangeSize"
                @current-change="currentChange"
                background>
            </el-pagination>
          </div>
        </div>
      </div>


      <!--服务商-->
      <div v-if="isIdentity == 1">
        <!-- 搜索 -->
        <el-row :gutter="15" class='kuai4'>
          <el-col :span="20">
            <div class="shurukuang1">
              <el-input v-model="fuwushangwhere.company_name" placeholder="请输入服务商名称" prefix-icon="el-icon-search" clearable></el-input>
            </div>
          </el-col>
          <!--<el-col :span="4">-->
          <!--  <div class="xialakuang">-->
          <!--    <el-select-->
          <!--      style="background: #E9EAF1 !important;"-->
          <!--      clearable-->
          <!--      v-model="fuwushangwhere.range"-->
          <!--      placeholder="请选择距离"-->
          <!--      class="ele-fluid">-->
          <!--      <el-option-->
          <!--        v-for="(item) in dict.type.distance"-->
          <!--        :label="item.label"-->
          <!--        :value="item.value"-->
          <!--      />-->
          <!--    </el-select>-->
          <!--  </div>-->
          <!--</el-col>-->
          <el-col :span="4">
            <div style="text-align: center;">
              <el-button type="primary" @click="fuwushangsearch">搜索</el-button>
            </div>
          </el-col>
        </el-row>

        <!--服务商 -->
        <el-row class="kuai5">
          <!--<el-col :span="24">-->
          <!--  <div class="sijilist">-->
          <!--    <div class="sijilistactive" style="width: 90px;" v-for="(item,index) in fuwushanglist" :key="index" :class="{sijilistactive1:index==fuwushangisShow}" @click="fuwushangisShowClick(item,index)">-->
          <!--      {{item.name}}-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</el-col>-->
        </el-row>

        <!-- 服务商 -->
        <el-row>
          <div class="sijiradio">
            <div class='kuai6' v-for="(item,index) in fuwushanglistList" :key="index" >
              <div class='kuai7'>
                <img v-if="index==fuwushanglistlistisShow" src="@/assets/images/home/xuanzhonganniu.png" alt="">
                <img v-else src="@/assets/images/home/weixuanzhonganniu.png" alt="">
              </div>
              <div class="listactive" :class="{listactive1:index==fuwushanglistlistisShow}" @click="fuwushanglistListClick(item,index)">
                <div>
                  <span class='font17'>{{item.company_name}}</span>
                  <span class='font17'>接单总量</span>
                  <span class='font17'>拒单总量</span>
                  <span class='font17'>完成总量</span>
                  <span class='font17'>催单总量</span>
                  <span class='font17'>投诉总量</span>
                </div>
                <div class='bianjuleft'>
                  <span class="font18">{{item.detailed_address}}</span>
                  <img class="font20" src="@/assets/images/home/juli.png" alt="">
                  <span class="font21">{{item.distance}}km</span>
                  <img src="@/assets/images/home/dianhua.png" alt="" class="font22">
                  <span class="font23">占位</span>
                </div>
              </div>
            </div>
            <el-empty description="暂无数据" v-if="fuwushanglistList.length == 0"></el-empty>
            <div class='bianju1' v-if="fuwushanglistList.length > 0">
              <el-pagination
                  style="text-align: center; margin-top: 20px"
                  layout="prev, pager, next"
                  :total="fuwushangtotal"
                  :page-size="fuwushangwhere.limit"
                  :current-page="fuwushangwhere.page"
                  @size-change="fuwushangChangeSize"
                  @current-change="fuwushangcurrentChange"
                  background>
              </el-pagination>
            </div>
          </div>
        </el-row>
      </div>

      <!--修理厂-->
      <div v-if="isIdentity == 2">
        <!-- 搜索 -->
        <!--<el-row :gutter="15" class='kuai4'>-->
        <!--  <el-col :span="24">-->
        <!--    <div class="shurukuang1">-->
        <!--      <el-input v-model="form.input" placeholder="请输入关键词" prefix-icon="el-icon-search"></el-input>-->
        <!--    </div>-->
        <!--  </el-col>-->
        <!--</el-row>-->

        <!-- 服务商修理厂 -->
        <el-row>
          <div class="sijiradio">
            <div class='kuai6' v-for="(item,index) in xiulichanglistlist" :key="index" >
              <div class="listactive" :class="{listactive1:index==xiulichanglistlistisShow}" @click="xiulichanglistListClick(item,index)">
                <div style="display: flex;justify-content: space-between;">
                  <span class="font24">{{item.name}}</span>
                  <span>
                    <img class="font20" src="@/assets/images/home/juli.png" alt="">
                  <span class="font34">{{item.juli}}</span>
                  </span>
                </div>
                <div style="margin-top: 10px;" v-if="item.tel != ''">
                  <span class="font35">{{item.tel}}</span>
                  <!--<img src="@/assets/images/home/dianhua.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;margin-left: 20px;">-->
                </div>
                <div style="margin-top: 10px;">
                  <span class='font17'>{{item.address}}</span>
                </div>
              </div>
            </div>
            <el-empty description="暂无数据" v-if="xiulichanglistlist.length == 0"></el-empty>
            <div class='bianju1' v-if="xiulichanglistlist.length > 0">
              <el-pagination
                  style="text-align: center; margin-top: 20px"
                  layout="prev, pager, next"
                  :total="xiulichanglistlisttotal"
                  :page-sizes="[10, 20, 30, 40, 50]"
                  :page-size="xiulichanglistlistwhere.limit"
                  :current-page="xiulichanglistlistwhere.page"
                  @size-change="xiulichanglistlistChangeSize"
                  @current-change="xiulichanglistlistcurrentChange"
                  background>
              </el-pagination>
            </div>
          </div>
        </el-row>
      </div>
      <div style="text-align: center;">
        <el-button
            @click="showcurrent = false">取消
        </el-button>
        <!--&& isDriverIdentity != 1-->
        <el-button
            v-if="isIdentity != 2"
            class="font31"
            type="primary"
            @click="save">确定
        </el-button>
      </div>
    </div>

  </div>
</template>

<script>
//引入高德地图
import AMapLoader from '@amap/amap-jsapi-loader';
//引入高德地图key
import setting from '@/config/config'
import {
  getget_send_driver,
  getoutsource_driver,
  getshare_driver,
  getget_cooperation,
  delay_send_map,
  corner,
  counts,
  Savesend,
  Saverepair,
  delay_send_mapdetails,
  getclone_driver
} from "@/api/orderModule";
import {kmUnit} from "@/utils";

export default {
  props:{
    // 字典数据
    dict: Object
  },
  data(){
    return{
      //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
      map:null,

      name:'',

      list:[
        {
          label:'待调度',
          corner:'',
        },
        {
          label:'服务中',
          corner:'',
        }
      ],
      isShow:0,

      form:{},

      isShow1:-1,
      keyword:'',
      dataListwhere:{
        page: 1,
        limit: 5,
        tabs:'0'
      },
      dataListtotal:0,
      dataList:[],
      // 选择的订单
      current:null,
      showcurrent:false,

      //统计
      statistics:{},


      //司机
      isIdentity:0,
      Identity:[
        {
          label:'司机'
        },
        {
          label: '服务商'
        },
        // {
        //   label: '附近修理厂'
        // }
      ],

      isDriverIdentity:0,
      DriverIdentity:[
        {
          label:'自营司机'
        },
        {
          label:'共享司机'
        },
        {
          label:'合作司机'
        },
        {
          label:'外协司机'
        }
      ],

      isstatus:1,
      StatusList:[
        {
          label:'空闲',
          status:'1'
        },
        {
          label:'忙碌',
          status:'2'
        },
        {
          label:'休息',
          status:'0'
        },
      ],
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 3, // 每页显示条目个数
      },
      // 总条目数
      total: 0,
      sijilistlistisShow:-1,
      sijilistList:[],
      //司机id
      driver_id: '',
      //司机身份
      //自营 self
      //共享 share
      driver_identity:'self',


      //服务商相关
      // 表格搜索条件
      fuwushangwhere: {
        page: 1, // 当前页数
        limit: 3 // 每页显示条目个数
      },
      // 总条目数
      fuwushangtotal: 0,
      fuwushanglist:[
        {
          name:'自营服务商'
        },
        {
          name:'合作服务商'
        },
        {
          name:'平台服务商'
        },
      ],
      fuwushangisShow:-1,

      fuwushanglistList:[],
      fuwushanglistlistisShow:0,


      // 修理厂
      // 表格搜索条件
      xiulichanglistlistwhere: {
        page: 1, // 当前页数
        limit: 3 // 每页显示条目个数
      },
      // 总条目数
      xiulichanglistlisttotal: 0,
      // 数组
      xiulichanglistlist:[],
      xiulichanglistlistisShow:100,
      //搜索
      searchvalue:'',
      //选择的数据
      selectitem:null,

      height:0,


      //详情数据
      detalis:{
        driver_id:{},
        order_info:{},
      }

    }
  },

  mounted() {
    // 获取派单地图列表
    this.getdelay_send_map();
    // 获取下角标
    this.getcorner();
    //调用统计接口
    this.getcounts();

    //获取地图左侧内容的高度 传值给地图子组件
    //this.height = this.$refs.element.offsetHeight + 20;  //减去的20是内边距距离

    // 高德地图
    window._AMapSecurityConfig = {
      securityJsCode: setting.secretkey,
    }
  },

  methods: {
    // 获取派单地图列表接口
    getdelay_send_map(){
      delay_send_map(this.dataListwhere).then(res => {
        console.log('派单地图',res)
        this.dataList = res.data.list;
        this.dataListtotal = res.data.count;
        //DOM初始化完成进行地图初始化
        this.initMap();
      })
    },
    // 左侧列表筛选条件
    querykeyword(){
      this.dataListwhere.keyword = this.keyword;
      this.getdelay_send_map();
    },
    // 当前页数改变事件
    dataListcurrentChange(val) {
      console.log("当前页数", val)
      this.dataListwhere.page = val;
      this.getdelay_send_map();
    },
    dataListChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.dataListwhere.limit = val;
      this.getdelay_send_map();
    },
    // 获取角标
    getcorner(){
      corner().then(res => {
        this.list[0].corner = res.data.delay_dispatch_count
        this.list[1].corner = res.data.in_service_count
      })
    },
    // 统计接口
    getcounts(){
      counts().then(res => {
        this.statistics = res.data;
      })
    },







    // 初始化高德地图
    initMap(page) {
      AMapLoader.load({
        key: setting.key,// 申请好的Web端开发者Key，首次调用 load 时必填
        version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins:['AMap.PlaceSearch','AMap.GeometryUtil'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {  //设置地图容器id
          mapStyle: "amap://styles/grey",
          zoom: 13,
          center: [116.397428, 39.90923],//地图中心点
          resizeEnable: true
        });

        // 显示所有订单案件地的角标
        var markers = this.dataList;
        console.log(markers,'markers')
        // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
        markers.forEach(marker => {
          if(marker.starting_point_coord.length != 0){
            var marker = new AMap.Marker({
              map: this.map,
              icon: new AMap.Icon({
                image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
                imageSize: new AMap.Size(30, 41),
              }),
              position: [marker.starting_point_coord[0], marker.starting_point_coord[1]],
              offset: new AMap.Pixel(-13, -30),
            });
          }
        });
        // 添加点标记
        // marker.setMap(this.map);
        var center = this.map.getCenter();
        var setFitViewBtn = document.getElementById('setFitView');
        this.map.setFitView(null, false, [150, 60, 100, 60]);
        var newCenter = this.map.getCenter();


        // 附近修理厂
        if(this.isIdentity == 2){
          var placeSearch = new AMap.PlaceSearch({
            // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
            // city: '010',
            type:['汽车维修'],
            pageSize: 5, // 单页显示结果条数
            pageIndex: page, // 页码
            //map: this.map, // 展现结果的地图实例
            panel: "panel", // 结果列表将在此容器中进行展示。
            autoFitView: false // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          });
          let that = this;
          let location = [];
          location[0] = that.current.starting_point_coord[0];
          location[1] = that.current.starting_point_coord[1];
          console.log(location,'位置坐标')
          // 获取当前订单的经度纬度
          var cpoint = location; //中心点坐标
          placeSearch.searchNearBy('', cpoint, 50000, function(status, result) {
            // 查询成功时，result即对应匹配的POI信息
            console.log(result)
            that.xiulichanglistlist = result.poiList.pois;
            that.xiulichanglistlisttotal = result.poiList.count;
            that.xiulichanglistlist.forEach(item => {
              let p1 = [location[0], location[1]];
              let p2 = [item.location.lng, item.location.lat];
              // 返回 p1 到 p2 间的地面距离，单位：米
              let dis = parseInt(AMap.GeometryUtil.distance(p1, p2));
              let km = kmUnit(dis);
              item.juli = km;
            })
            console.log(that.xiulichanglistlist);
          });
        }


      }).catch(e => {
        console.log(e);
      })
    },

    //获取自营司机司机列表
    getList(){
      // 合作司机没有状态
      if(this.isDriverIdentity == 2){
        delete this.where.status
      }else {
        this.where.status = this.StatusList[this.isstatus].status;
      }
      getget_send_driver(this.current.id,this.where).then(res => {
        console.log(res)
        this.total = res.data.count;
        this.sijilistList = res.data.list;
      })
    },
    //获取共享司机
    getGongxiang(){
      this.where.status = this.StatusList[this.isstatus].status;
      getshare_driver(this.current.id,this.where).then(res => {
        console.log(res)
        this.total = res.data.count;
        this.sijilistList = res.data.list;
      })
    },
    //获取合作司机
    getWaixie(){
      getoutsource_driver(this.current.id,this.where).then(res => {
        console.log(res)
        this.total = res.data.count;
        this.sijilistList = res.data.list;
      })
    },
    //获取外协司机
    getgetclone_driver(){
      // 外协司机没有状态
      if(this.isDriverIdentity == 3){
        // 没有状态筛选
        delete this.where.status
        // 没有距离筛选
        delete this.where.range
      }else {
        this.where.status = this.StatusList[this.statusisShow].status;
      }
      getclone_driver(this.current.id,this.where).then(res => {
        console.log(res)
        this.total = res.data.count;
        this.sijilistList = res.data.list;
      })
    },
    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList();
    },


    //选择待调度还是服务中
    clickactive(item,index){
      this.isShow = index;
      if(item.label == '服务中'){
        this.dataListwhere.tabs = '1';
        this.getdelay_send_map()
      }else {
        this.dataListwhere.tabs = '0'
        this.getdelay_send_map()
      }

    },

    //选择列表
    activeList(item,index){
      this.isShow1 = index;
      this.current = item;
      console.log(this.current)
      this.showcurrent = true;
      //获取服务商
      if(this.isIdentity == 1){
        this.getFuwushang();
      }

      //获取附近修理厂
      if(this.isIdentity == 2){
        this.initMap();
      }

      //获取自营司机列表
      if(this.isIdentity == 0){
        this.isDriverIdentity = 0;
        this.getList();
      }

      // 获取下订单详情
      delay_send_mapdetails(item.id).then(res => {
        console.log(res)
        this.detalis = res.data;
      })

    },

    //选择右侧身份
    activeisIdentity(item,index){
      this.isIdentity = index;
      //获取服务商
      if(this.isIdentity == 1){
        this.getFuwushang();
      }

      //获取附近修理厂
      if(this.isIdentity == 2){
        this.initMap();
      }

      //获取自营司机列表
      if(this.isIdentity == 0){
        this.getList();
        this.isDriverIdentity = 0;
      }
    },

    //选择司机身份
    activeDriverIdentity(item,index){
      this.isDriverIdentity = index;
      // 自营司机
      if(this.isDriverIdentity == 0){
        this.getList();
        //司机身份
        this.driver_identity = 'self'
      }else if(this.isDriverIdentity == 1){
        //共享司机
        this.getGongxiang()
        //司机身份
        this.driver_identity = 'share'
      }else if(this.isDriverIdentity == 2){
        //合作司机
        this.getWaixie();
      }else if(this.isDriverIdentity == 3){
        //外协司机
        this.getgetclone_driver()
        //司机身份
        this.driver_identity = 'waixie'
      }
    },
    //选择司机状态
    activeisstatus(item,index){
      this.isstatus = index;
      console.log('11111')
      // 自营司机
      if(this.isDriverIdentity == 0){
        this.where.status = this.StatusList[this.isstatus].status;
        this.getList();
      }else if(this.isDriverIdentity == 1){
        //共享司机
        this.where.status = this.StatusList[this.isstatus].status;
        this.getGongxiang()
      }else if(this.isDriverIdentity == 2){
        //合作司机
        this.where.status = this.StatusList[this.isstatus].status;
        this.getWaixie()
      }else if(this.isDriverIdentity == 3){
        //外协司机
        this.getgetclone_driver()
      }
    },
    // 司机列表点击搜索
    search(){
      if(this.driver_identity == 'self'){
        this.getList();
      }else if(this.driver_identity == 'share'){
        this.getGongxiang();
      }else if(this.driver_identity == 'waixie'){
        this.getgetclone_driver();
      } else {
        this.getWaixie();
      }
    },
    //司机列表选择
    sijilistListClick(item,index){
      this.sijilistlistisShow = index;
      //司机id
      this.driver_id = item.id;
    },

    //服务商
    fuwushangisShowClick(item,index){
      this.fuwushangisShow = index;
    },
    fuwushanglistListClick(item,index){
      this.fuwushanglistlistisShow = index;
      //服务商id
      this.facilitator_id = item.id;
    },
    //获取服务商
    getFuwushang(){
      this.fuwushangwhere.oid = this.current.id;
      getget_cooperation(this.fuwushangwhere).then(res => {
        console.log(res)
        this.fuwushangtotal = res.data.count;
        this.fuwushanglistList = res.data.list;
      })
    },
    // 服务商当前页数改变事件
    fuwushangcurrentChange(val) {
      console.log("当前页数", val)
      this.fuwushangwhere.page = val;
      this.getFuwushang();
    },
    fuwushangChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.wuwushangwhere.limit = val;
      this.getFuwushang();
    },
    // 服务商搜索
    fuwushangsearch(){
      this.getFuwushang();
    },

    //修理厂
    xiulichanglistListClick(item,index){
      this.xiulichanglistlistisShow = index;
      this.selectitem = item;
      console.log(this.selectitem);
      this.selectitem.repair_site = [];
      this.selectitem.repair_site.push(item.pname,item.cityname,item.adname,item.address);
      this.selectitem.location1 = [];
      this.selectitem.location1.push(item.location.lng,item.location.lat);
    },
    //搜索事件
    // xiulichangsearch(){
    //
    // },
    // 服务商当前页数改变事件
    xiulichanglistlistcurrentChange(val) {
      console.log("当前页数", val)
      this.xiulichanglistlistwhere.page = val;
      this.initMap(this.xiulichanglistlistwhere.page);
    },
    xiulichanglistlistChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.xiulichanglistlistwhere.limit = val;
      this.initMap();
    },

    //返回按钮
    returnPage(){
      this.$emit('showdispatchMap',false) ;
    },

    //点击确定
    save() {
      if(this.isIdentity == 0){
        var data = {
          driver_id: this.driver_id,
          driver_identity: this.driver_identity
        }
        // 用于判断下如果时外协司机去掉身份
        if(this.isDriverIdentity == 3){
          delete data.driver_identity
        }else {
          data.driver_identity = this.driver_identity
        }
        // 判断是否没有选择司机
        if(this.driver_id == ''){
          this.$message.error('请选择司机')
        }else {
          Savesend(this.current.id,data).then(res => {
            if(res.code === 200){
              this.$message.success(res.msg);
            }else {
              this.$message.error(res.msg)
            }
          }).catch(e => {
            console.log(e.msg)
          })
        }
      }else if(this.isIdentity == 1){
        var data = {
          facilitator_id: this.facilitator_id,
        }
        if(this.facilitator_id == ''){
          this.$message.error('请选择服务商')
        }else {
          Savesend(this.current.id,data).then(res => {
            if(res.code === 200){
              this.$message.success(res.msg);
            }else {
              this.$message.error(res.msg)
            }
          }).catch(e => {
            console.log(e.msg)
          })
        }
      }else if(this.isIdentity == 2){
        if(this.selectitem == null){
          this.$message.error('请选择修理厂')
        }else {
          let data = {
            odd: this.current.order_id,
            repair_gid: this.selectitem.id,
            repair_name: this.selectitem.name,
            repair_site: this.selectitem.repair_site,
            phone: this.selectitem.tel,
            linkman: '',
            location: this.selectitem.location1
          };
          Saverepair(this.data.id,data).then(res => {
            if(res.code === 200){
              this.$message.success(res.msg);
            }else {
              this.$message.error(res.msg)
            }
          }).catch(e => {
            console.log(e.msg)
          })
        }
      }
      this.showcurrent = false;
      this.getdelay_send_map();
    },

  }

}
</script>

<style lang="scss">
#container{
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}
.content{
  width: 100%;
  position: absolute;
  top: 20px;
  //padding: 20px;
}
.content1{
  //display: flex;
  //align-items: flex-start;
  width: 25%;
  //padding-left: 20px;
  //padding-right: 20px;
  position: absolute;
  //top: 20px;
  left: 20px;
}
.content_input{
  display: inline-block;
  border-radius: 10px;
  background: #FFFFFF;
  padding: 10px;
  .el-input__inner{
    border: none !important;
  }
}
.content_input1{
  width: 140px;
  padding-top: 20px;
  padding-bottom: 15px;
  display: inline-block;
  border-radius: 10px;
  margin-left: 20px;
  cursor: pointer;
  background: #5D5E5E;
}
.content_input3{
  width: 100%;
  background: #FFFFFF;
  padding: 0 20px 20px 20px;
  display: inline-block;
  border-radius: 10px;
}
.content_input4{
  width: 30%;
  background:rgba(0,0,0,0.8);
  padding: 10px 10px 10px 10px;
  //display: inline-block;
  position: absolute;
  top: 2.2%;
  right: 0;
  .content_input4_div{
    background: #0c0b0b;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    color: #f2f2f2;
    display: inline-block;
    padding: 8px 18px 8px 18px;
    margin-right: 20px;
    cursor: pointer;
  }
  .content_input4_div:last-child{
    margin-right: 0;
  }
  .content_input4_div1{
    background: #FF9B05;
  }
  .content_input4_div:hover{
    background: #FF9B05;
  }

  .DriverIdentityList{
    font-size: 16px;
    font-weight: 500;
    color: #cfcfcf;
    display: inline-block;
    width: 25%;
    cursor: pointer;
    text-align: center;
  }
  .DriverIdentityList1{
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    border-bottom: 2px solid #FF9B05;
    padding-bottom: 10px;
  }

  .StatusList{
    width: 33%;
    display: inline-block;
    background: #4A4D4D;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #cfcfcf;
    cursor: pointer;
  }
  .StatusList:hover{
    padding-top: 10px;
    padding-bottom: 10px;
    background: #0C0B0B;
    color: #FFFFFF;
  }
  .StatusList1{
    padding-top: 10px;
    padding-bottom: 10px;
    background: #0C0B0B;
    color: #FFFFFF;
  }

  .listactive{
    width: 100%;
    background: #353535;
    border-radius: 6px;
    padding: 20px;
  }
  .listactive1{
    background: #0c0b0b;
  }

}

.sijilist{
  .sijilistactive{
    cursor: pointer;
    display: inline-block;
    width: 64px;
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    color: #868792;
    margin: 10px;
  }
  .sijilistactive1{
    color: #FF9B05;
    border-bottom: 4px solid #FF9B05;
  }
}

.content2{
  width: 316px;
  padding: 20px;
  background: #FFFFFF;
  margin-top: 20px;
  border: 1px solid #ffffff;
  border-radius: 10px;
}
.fontcolor{
  font-size: 14px;
  font-weight: 400;
  color: #7e7f8c;
}
.fontcolor1{
  color: #FF9B05;
}

.shoufengqin{
  .el-collapse{
    border: none !important;
  }
  .el-collapse-item__wrap{
    border: none !important;
  }
  .el-collapse-item__header{
    border: none !important;
  }
  .el-collapse-item__header.is-active{
    border: none !important;
    color: #FF9B05 !important;
  }
}

.spankuai{
  font-size: 20px;
  font-weight: 500;
  color: #202033;
  display: inline-block;
  padding: 7px 15px 6px 15px;
  cursor: pointer;
  margin-right: 20px;
  text-align: center;
}
.spankuai1{
  color: #202033;
  font-weight: 700;
  border-bottom: 3px solid #FF9B05;
}
.spankuai:hover{
  color: #202033;
  font-weight: 700;
  border-bottom: 3px solid #FF9B05;
}

.kuai{
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
}
.kuai:hover{
  border: 1px solid #fea737;
  background: #FFF6EB !important;
}
.kuai1{
  border: 1px solid #fea737;
  background: #FFF6EB !important;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.jiaobiao{
  width: 34px;
  height: 28px;
  background-image: url("../../../assets/images/home/order/jiaobiao.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: -4px;
  right: 0;
  text-align: center;
}

.tanchuang{
  width: 25%;
  position: absolute;
  top: 30%;
  left: 40%;
  margin: -50px 0 0 -50px;
}
.tanchuang_b{
  background:rgba(127,127,127,0.8);
  padding: 20px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.bgbg{
  background: #8b8b8b;
  border-radius: 8px;
  padding: 15px;
}

.zuoceliebiaotitle{
  display: flex;align-items: center;justify-content: space-around;margin-top: 10px;
}
.zuoceliebiaotitle1{
  margin-left: 20px;cursor: pointer;
}
.bianjuleft{
  margin-top: 20px;
}

.font1{
  width: 21px;height: 21px;vertical-align: middle;margin-right: 10px;
}
.font2{
  font-size: 14px;font-weight: 500;color: #202033;vertical-align: middle;
}
.font3{
  font-size: 14px;font-weight: 500;color: #202033;
}
.font4{
  font-size: 12px;font-weight: 500;color: #ffffff;margin-left: 4px;
}
.bianju1{
  margin-bottom: 10px;
}
.font5{
  background: #FEA737;text-align: center;padding-top: 10px;padding-bottom: 10px;border-top-left-radius: 6px;border-top-right-radius: 6px;display: flex;align-items: center;justify-content: center;
}
.font6{
  font-size: 18px;font-weight: 500;color: #ffffff;
}
.font7{
  display: inline-block;background: #FFD197;font-size: 14px;font-weight: 500;color: #683915;margin-left: 15px;
}
.font8{
  font-size: 14px;font-weight: 400;color: #ffffff;
}
.font9{
  color: #FFFFFF;margin-left: 10px;
}
.font10{
  height: 1px;opacity: 0.45;border: 1px solid #ffffff;
}
.kuai2{
  margin-top: 10px; display: flex;align-items: center;
}
.font11{
  width: 3px;height: 13px;background: #ffffff;display: inline-block;margin-right: 10px;
}
.font12{
  font-size: 14px;font-weight: 700;color: #ffffff;
}
.font13{
  width: 10px;height: 10px;background: #25eb61;border-radius: 50%;display: inline-block;margin-right: 10px;
}
.font14{
  font-size: 14px;font-weight: 500;color: #ffffff;
}
.font15{
  width: 16px;height: 16px;vertical-align: middle;margin-right: 10px;
}
.font16{
  opacity: 0.7;font-size: 14px;font-weight: 400;color: #ffffff;
}
.kuai3{
  text-align: center;margin-top: 20px;
}
.kuai4{
  margin-top: 15px;
}
.kuai5{
  margin-top: 15px;border-bottom: 1px solid #6F6F6F;
}
.kuai6{
  margin: 20px; display: flex;align-items: center;cursor: pointer;
}
.kuai7{
  margin-right: 20px;
}
.font17{
  font-size: 16px;font-weight: 500;color: #fff;
}
.font18{
  font-size: 14px;font-weight: 400;color: #868792; vertical-align: middle;margin-right: 10px;
}
.font20{
  width: 16px;height: 16px; vertical-align: middle; margin-right: 20px;
}
.font21{
  font-size: 14px;font-weight: 400;color: #868792; vertical-align: middle;
}
.font22{
  width: 20px;height: 20px;vertical-align: middle;margin-left: 20px;
}
.font23{
  display: inline-block; width: 140px;color: #0c0b0b;
}
.font24{
  font-size: 16px;font-weight: 500;color: #FFFFFF;
}
.font25{
  width: 10px;height: 10px;background: #25EB61;border-radius: 50%; display: inline-block;margin-right: 20px;
}
.font26{
  width: 10px;height: 10px;background: #FEA837;border-radius: 50%; display: inline-block;margin-right: 20px;
}
.font27{
  width: 10px;height: 10px;background: #FF2525;border-radius: 50%; display: inline-block;margin-right: 20px;
}
.font28{
  margin-left: 20px;vertical-align: middle;
}
.font29{
  vertical-align: middle;width: 13px;height: 13px;margin-left: 10px;
}
.font30{
  margin-left: 10px;vertical-align: middle;
}
.font31{
  margin-left: 20px;
}
.font32{
  font-size: 14px;font-weight: 400;color: #fff; vertical-align: middle;margin-left: 15px;
}
.font33{
  width: 20px;height: 20px;vertical-align: middle;margin-left: 11px;
}
.font34{
  font-size: 14px;font-weight: 400;color: #FFFFFF; vertical-align: middle;
}
.font35{
  font-size: 16px;font-weight: 400;color: #fff; vertical-align: middle;
}
</style>
